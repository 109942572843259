import React from 'react'

// import FBPlugin from 'src/components/fbplugin.jsx'

import { graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Img from 'gatsby-image'

import Layout from 'src/components/layout'
import Row from 'src/components/row'

const IndexPage = ({ data }) => (
  <Layout>
    <div className="content">
      <Slider
        dots
        infinite
        fade
        autoplay
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        arrows={false}
      >
        {data.images.edges.map(({ node: { childImageSharp: { fluid } } }) => (
          <div key={fluid.src}>
            <Img fluid={fluid} />
          </div>
        ))}
      </Slider>


      <Row columns={2}>
        <div>
          <h2>Who We Are</h2>
          <p>
            arc Plumbing is a plumbing company specializing in residential,
            commercial and industrial work. We have been serving the Greater
            Toronto Area and South-Central Ontario for over 43 years. We’re proud
            to be a Licensed, Insured and WSIB-compliant plumbing contractor.
            We’re a known and trusted name in the industry, and serve homeowners,
            manufacturers, food service firms, restaurants, hotels, recreational
            facilities, vehicle repair centres and more.
          </p>

          <h2>Our Mission</h2>
          <p>
            We strive to offer our customers a complete solution for all their
            plumbing service, renovation and new construction needs. Our mission
            is to provide excellent customer service with exceptional quality and
            at a reasonable cost.
          </p>
        </div>
        <div>

          <h2>Why choose ARC?</h2>
          <ul>
            <li>
              Experience - over 40 years’ experience with a diversified portfolio.
            </li>
            <li>
              Trust - a good-standing history in the business.
            </li>
            <li>
              Growth - strong roster of existing clients and references.
            </li>
            <li>
              Care - Superior customer service.
            </li>
            <li>
              Organized - Projects delivered on time + on budget.
            </li>
            <li>
              Quality - we take pride in our work, and employee highly skilled
              and qualified crew members and sub-trades.
            </li>
            <li>
              Qualified - We are licensed, insured, and WSIB compliant; we
              stand behind and warranty our work.
            </li>
          </ul>
          <p>
            We pride ourselves on our reputation and will go above and beyond to
            ensure our customers are satisfied. As a smaller, family owned and
            operated firm we deliver high-quality projects, quickly, without the
            disadvantages of the high overhead and bureaucracy associated with
            larger firms. That said, our size, stability and experience give our
            clients protection, and allow our clients to avoid risks associated
            with unlicensed, uninsured and unprofessional plumbing contractors.
            We insist on legitimate workmanship, with building permits, municipal
            inspections and engineer's approvals. Our goal is to please and
            ensure that all work is completed professionally and to code. We are
            happy to furnish references upon request.
          </p>

          <p>
            <Link to="/contact-us">Call or email us</Link> now to book an
            appointment, schedule a free quote, or get help with your plumbing
            question.
          </p>
        </div>
      </Row>
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }

    images: allFile(filter: { relativeDirectory: { regex: "/home-slideshow/" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 900, maxHeight: 400, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
